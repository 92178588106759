.recovery-container {
    width: 300px;
    min-width: 200px;
    margin: auto;
    padding: 5vh;
    border: 1px solid black;
    border-radius: 2vw;
    background-color: rgba(0, 0, 0, 1);
}

.recovery-title {
    text-align: center;
    color: #eaeaea;
}

.recovery-attribute {
    margin-bottom: 2vh;
}

.recovery-attribute label {
    display: block;
    margin-bottom: 1.5vh;
    color: #eaeaea;
}

.recovery-attribute input {
    width: 100%;
    padding: 1.5vh 0 1.5vh 0;
    border: 1px solid #ddd;
    border-radius: 1vw;
    text-indent: 5px;
}

.submit-button-recovery button {
    width: 100%;
    padding: 2vh;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 1vw;
    margin-top: 10px;
    cursor: pointer;
}

.submit-button-recovery button:hover {
    background-color: #2980b9;
}

.forgotpassword-page .grecaptcha-badge {
    display: block !important;
    visibility: visible !important;
  }

@media (max-width: 400px){
    .recovery-container{
        width: auto;
        min-width: 260px;
    }
}
