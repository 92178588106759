/* Adjust the position and size of the flatpickr time picker */
.flatpickr-calendar {
    width: 180px !important; 
    left: 50% !important;
    transform: translateX(-50%) !important; /* Center the calendar horizontally */
  }
  
  /* Adjust the row height within the time picker to be more compact */
  .flatpickr-time .flatpickr-time-separator,
  .flatpickr-time .numInputWrapper {
    height: 35px !important; 
    line-height: 35px !important; 
  }

  .flatpickr-calendar.animate.open {
    animation: none !important; 
  }


  
  .flatpickr-time .numInputWrapper input {
    text-align: center !important;
  }

  .flatpickr-calendar.hasTime .flatpickr-time{
    border: none !important;
  }

  