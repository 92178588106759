.graphics-container {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 98vw;
    height: auto; 
    margin: 12vh 10px 10px 10px;
  }
  
  .graphics-content {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 90%; 
    height: 100%;
    background-color: black;
    padding: 5px;
    max-width: 900px;
    border: 1px solid black;
    border-radius: 10px;
  }
  
  .filter-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 30%; 
    /* margin-right: 2%; */
    border-radius: 10px;
    max-width: 200px;
    align-items: center;
  }

  .loading-data-graphic, .error-data-graphic {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; 
    color: #666; 
    font-size: 24px; 
    font-weight: bold;
  }
  
  .dropdown, .today {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    font-size: 16px;
    width: 160px;
    text-align: center;
    margin-bottom: 10px;
    background-color: white;
    }

    .dropdown.today.disabled {
        width: 140px;
        background-color: lightgray !important;
        border: 1px solid #ced4da;
    }
  
  .chart-section {
    flex-basis: 80%; 
    background-color: #f5f5f5; 
    border-radius: 5px;
    height: 70vh;
  }

  .datePicker-input .react-datepicker__input-container input{
    width: 155px;
    background-color: white;
    color: black;
    border: 1px solid #ced4da;
    border-radius: 10px;
  }

  .datePicker-input .react-datepicker__input-container input::placeholder {
    color: black; 
    text-align: center; 
  }

  .barChart-graphic{
    width: 100%;
    height: 100%;
    max-width: 680px;
  }
  /*-----------------*/

  .control-button-graphic {
    background-color: #e9e9ed;
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 10px 10px 10px 10px;
    font-size: 16px;

    text-align: center;
  }

  .control-button-graphic:active,
  .control-button-graphic:hover,
  .control-group-graphic select:active,
  .control-group-graphic select:hover,
  .control-group-graphic input:active,
  .control-group-graphic input:hover {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }

  .control-button-graphic:hover{
    background-color: #cacacf;
  }

  .manage-tools-dropdown-graphic{
    background-color: #fff;
    min-width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
   
  }

  .dropdown-item-graphic {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }


  .control-group-graphic {
    display: block;
    width: 100%;
    justify-content: space-between; 
    border-radius: 10px 10px 0 0;
  }

  .control-group-graphic select {
    width: 100%;
  }
  
  @media (max-width: 970px) {
    .graphics-content {
      flex-direction: column;
      align-items: center; 
    }
  
    .filter-section, .chart-section {
      flex-basis: auto; 
      max-width: none; 
      width: 100%; 
      margin-right: 0; 
      margin-bottom: 20px; 
    }

    .chart-section{
      max-height: 500px;
      min-height: 450px;
    }

    .control-button-graphic {
      border-radius: 10px 10px 0 0;
    } 
  
    .filter-section {
      order: -1; 
      border: 0;
    }

    .dropdown.today.disabled{
      width: 100%;
    }


    .react-datepicker-wrapper{
      width: 98%;
    }

    .datePicker-input .react-datepicker__input-container input{
      width: 100%;
    }
    

    .dropdown{
      margin-bottom: 0;
      background-color: #e9e9ed;
    }

    .datePicker-input .react-datepicker__input-container input{
      background-color: #e9e9ed;
    }

    .barChart-graphic{
      max-width: 854px;
    }
    
  }






  