.App {
  text-align: center;
}

.home-container {
  display: flex;
  margin-top: 17vh;
  justify-content: center;
  height: auto;
}

/* .logo, .pc-image {
  display: none;
}

.purpose-container img {
  display: block;
} */

/*--------------------------------*/

.time-container{
    margin: 12vh 0 4vh 0;
    padding: 5vh;
    border: 1px solid black;
    border-radius: 2vw;
    background-color: rgba(0, 0, 0, 1);
    max-width: 690px;
  }
  
  .time-firstRow-element {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: auto;
    padding: 0vh 4vh 4vh;
    border: 1px solid white;
    border-radius: 2vw;
    background-color: black;
    color: white;
  }
  
  .time-SecondRow-element {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    width: auto;
    margin: 2vh auto;
    padding: 0vh 4vh 2vh;
    border: 1px solid white;
    border-radius: 2vw;
    background-color: black;
    color: white;
  }
  .time-firstRow-element h1 {
    text-align: center;
  }
  
  .time-SecondRow-element h1 {
    text-align: center;
  }
  
  .time-input{
    width: 160px;
    height: 70px;
    font-size: 30px;
    text-align: center;
    border-radius: 1vw;
    margin-left: 20px;
    border: 1px solid white;
    background-color: #161b21;
    color: white;
  }

  .break-input{
    width: 160px;
    height: 70px;
    font-size: 30px;
    text-align: center;
    border-radius: 1vw;
    border: 1px solid white;
    background-color: #161b21;
    color: white;
  }
  
  .time-add {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width: auto;
    border: 1px solid black;
    border-radius: 1vw;
    max-width: calc(50% - 10px);
    cursor: pointer;
  }
  
  .time-add:hover {
    background-color: #c6d6e0;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  /*************************************************/

  .time-SecondRow-element.schedule-mode {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
}

.time-SecondRow-element.schedule-mode > div {
  flex-basis: calc(33% - 20px); 
  margin: 10px; 
}

.job-input, .recurrence-input{
  display: flex;
  margin: 0 auto 2vh auto;
  height: 5vh;
  width: 200px;
  text-align: center;
  border-radius: 1vw;
  border: 1px solid white;
  background-color: #161b21;
  color: white;
}

  /*************************************************/

  .branding {
    text-align: center;
    margin-top: 7vh;
    flex: 1;
    min-width: 300px;
    margin-right: 50px;
  }

  .intro-text {
    color: #333;
    margin: 20px 0; 
    padding: 0 10%; 
    font-size: 1.2rem; 
    max-width: 300px; 
    margin: auto; 
  }

  @media (min-width: 1030px) {
    .home-container {
      display: flex;
      align-items: stretch; 
      margin-bottom: 3vh;
    }
  
    .branding, .visual {
      height: 100%; 
    }
  }

  
.intro-button {
  padding: 10px 20px;
  margin-top: 20px; 
  font-size: 1rem;
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.intro-button:hover{
  color: #bfbe6e;
}
  
  .logo-container {
    display: flex;
    justify-content: center; 
  }
  
  .logo {
    max-width: 150px;
    height: auto;
  }
  
  .title {
    color: black; 
    font-size: 50px;
    margin-bottom: 0.5rem; 
  }
  
  .tagline {
    color: #8f8f8f; 
    font-size: 1rem; 
    letter-spacing: 1px;
  }
  
  .pc-image {
    
    min-width: 300px;
    width: 700px;
    height: 530px;
    margin-right: 2rem; 
  }

  .visual{
    max-height: fit-content; 
    overflow: hidden; 
  }


  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .branding, .pc-container {
    animation: fadeIn 1s ease-out;  
  }
  
  .pc-container {
    animation-delay: 0.5s; 
  }

  @media (max-width: 1100px) {
    .home-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      margin-bottom: 0;
    }
    .intro-text {
      max-width: 400px;
    }
  
    .visual, .branding {
      width: 100%; 
      text-align: center;
      margin: auto; 
      height: 100%;
    }

  
    .title, .tagline {
      margin-bottom: 20px;
    }

    .pc-image{
      margin-right: 0;
    }

    .intro-button{
      margin-bottom: 15px;
    }
  }

  @media (max-width: 700px){
    .pc-image {
      width: 90vw;
      height: 100%;
    }
  }


