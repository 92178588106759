.contact-container {
    width: 300px;
    min-width: 200px;
    margin: 12vh 0 5vh 0;
    padding: 25px 35px 30px 35px;
    border: 1px solid black;
    border-radius: 2vw;
    background-color: rgba(0, 0, 0, 1);
}

.contact-title {
    text-align: center;
    color: #eaeaea;
}

.contact-attribute {
    margin-bottom: 2vh;
}

.contact-attribute label {
    display: block;
    margin-bottom: 1.5vh;
    color: #eaeaea;
}

.contact-attribute input {
    width: 100%;
    padding: 1.5vh 0 1.5vh 0;
    border: 1px solid #ddd;
    border-radius: 1vw;
    text-indent: 5px;
}

.contact-attribute textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 1.5vh 0 1.5vh 0;
    border: 1px solid #ddd;
    border-radius: 1vw;
    text-indent: 5px;
    resize: none; 
    overflow: auto; 
    height: 15vh; 
    max-height: 50vh; 
    }

.submit-button-contact button {
    width: 100%;
    padding: 2vh;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 1vw;
    cursor: pointer;
}

.submit-button-contact button:hover {
    background-color: #2980b9;
}


.contact-createAccount {
    text-align: center;
    margin-top: 3vh;
}

.contact-createAccount a {
    color: #3498db;
    text-decoration: none;
}

.contact-createAccount a:hover {
    text-decoration: underline;
}

.contact-privacy{
    margin-bottom: 2vh;
    color: white;
}

.privacy-checkbox {
    color: #3498db;
    text-decoration: none;
}

.privacy-checkbox:hover {
    text-decoration: underline;
}

.contact-page .grecaptcha-badge {
    display: block !important;
    visibility: visible !important;
}

@media (max-width: 400px) {
    .contact-container {
        width: 75%;
        min-width: 260px;
    }
}