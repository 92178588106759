.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5vh 10px;
  background: rgba(0, 0, 0, 0.28); 
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 4rem;
}

.show-side-menu {
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}

.hidden {
  display: none;
}



.navbar-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
  transition: transform 0.3s ease;
}

.navbar-item {
  padding: 10px 15px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-right {
  margin: 0 10px 0 auto;
}

.nav-link {
  text-decoration: none;
  color: #fff;;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease;
  cursor: pointer;
  margin-right: 5px;
}

.hamburger-icon {
  display: none;
}

.line {
  background-color: #ecf0f1;
  height: 3px;
  margin: 5px 0;
  transition: transform 0.3s ease;
}

@media screen and (max-width: 560px) {
  .navbar-container {
    padding: 0.5vh 10px;
  }

  .navbar-toggle {
    display: flex;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%); 
    cursor: pointer;
    z-index: 1002; 
  }

  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem; 
    margin-right: 30px;
    
  }

  .line {
    width: 100%;
    height: 2px; 
    background-color: #fff; 
  }

  .navbar-list {
    display: none; 
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 200px; 
    background: rgba(0, 0, 0, 0.28);

    transform: translateX(100%);
    transition: transform 0.3s ease;
    padding-top: 60px;
    box-sizing: border-box;
  }

  .navbar-list.show-menu {
    transform: translateX(0); 
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .navbar-item {
    width: 100%;
    text-align: left;
    padding: 15px; 
    box-sizing: border-box; 
  }

  
  
  .navbar-item:hover {
    background: rgba(255, 255, 255, 0.28);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .navbar-item.navbar-right.out:hover{
    background: rgba(255, 126, 126, 0.28);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .navbar-item:not(.navbar-item.navbar-right.out) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
  }

  .show-menu {
    display: flex; 
    transform: translateX(0);
  }

  .navbar-left {
    position: static; 
    padding: 10px;
    transform: none;
    left: auto;
    top: auto;
    margin-right: auto; 
  }
}