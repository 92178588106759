.activate-container{
    width: 400px;
    min-width: 200px;
    margin: auto 10px;
    padding: 5vh;
    border: 1px solid black;
    border-radius: 2vw;
    background-color: rgba(0, 0, 0, 1);
    color: white;
}

.modal-activate{
    position: fixed;
    width: 100vw;
    height: 79vh;
    padding: 30px 0 30px 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
.modal-activate-title {
    margin-top: 20vh;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}


.button-activate button {
    width: 100%;
    padding: 2vh;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 1vw;
    cursor: pointer;
}

.button-activate button:hover {
    background-color: #2980b9;
}

@media (max-width: 500px){
    .activate-container{
        width: auto;
        min-width: 200px;
    }
}
