.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0; 
    right: 0; 
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    z-index: 1000;
}

.cookie-banner button {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 5px;
}

.cookie-banner p{
    white-space: collapse;
}