.register-container {
    width: 20vw;
    min-width: 300px;
    margin: 12vh 0 5vh 0;
    padding: 3vh 5vh 3vh 5vh;
    border: 1px solid black;
    border-radius: 2vw;
    background-color: black;
    color: white;
  }

  .register-container.register {
    width: 400px;
  }
  
.register-title {
    text-align: center;
    color: #eaeaea;
}

.register-attribute {
    margin-bottom: 1vh;
}

.register-attribute label {
    display: block;
    margin-bottom: 1.5vh;
    color: #eaeaea;
}

.register-attribute input {
    width: 100%;
    padding: 1.5vh 0 1.5vh 0;
    border: 1px solid #ddd;
    border-radius: 1vw;
    text-indent: 5px;
}

.submit-button-register button {
    width: 100%;
    padding: 2vh;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 1vw;
    margin-top: 1.5vh;
    cursor: pointer;
}

.submit-button-register button:hover {
    background-color: #2980b9;
}

.register-goToLogin {
    text-align: center;
    margin-top: 3vh;
    color: #aeaeae;
}

.register-goToLogin a {
    color: #3498db;
    text-decoration: none;
}

.register-goToLogin a:hover {
    text-decoration: underline;
}

.instructions-register{
    padding: 0;
  }

.instructions-register li {
    padding-bottom: 10px;   
}

.tooltip-content {
    text-align: left;
  }

.list-item {
    color: #000; 
}

.list-item.valid {
    color: green; 
}

.signup-page .grecaptcha-badge {
    display: block !important;
    visibility: visible !important;
  }

.privacy-checkbox {
    color: #3498db;
    text-decoration: none;
}

.privacy-checkbox:hover {
    text-decoration: underline;
}

.signup-privacy {
    margin: 2vh 0 2vh 0;
    color: white;
}


@media (max-width: 480px){
    .register-container.register{
        width: auto;
    }
}

@media (max-width: 400px){
    .register-container{
        width: auto;
        min-width: 260px;
    }
}