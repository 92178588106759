#footer-container {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #000;
  color: #ecf0f1;
  padding: 0.5vh 0 0.5vh 0 ;
  text-align: center;
  height: 3rem;
  z-index: 1001;
}

.footer-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin: 0;
  padding: 0;
}

.footer-element {
  margin: 0 15px;
}

.footer-element a {
  text-decoration: none;
  color: #ecf0f1;
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-element a:hover {
  color: #3498db;
}


@media (max-width: 320px){
  .footer-element {
    margin: 0 8px;
  }
}



