.password-container{
    position: relative;
}

.password-strength-message {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 0.8em;
    background-color: black;
}

.password-strength-message.weak {
    color: red;
}

.password-strength-message.medium {
    color: rgb(238, 255, 0);
}

.password-strength-message.strong {
    color: rgb(0, 156, 0);
}