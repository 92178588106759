
.historial-container {
  margin: 12vh 10px 10px 10px;
  }

  .historial-container h2{
    text-align: center;
  }

  .historial-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    min-width: 298px;
  }
  
  .historial-caption {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .historial-header {
    background-color: #f2f2f2;
    padding: 8px;
    text-align: center; 
    background-color: black;
    color: white;
  }
  
  .historial-cell {
    padding: 8px;
    border: 1px solid black;
    text-align: center;
    word-wrap: break-word;
  }

.historial-cell button {
  background-color: #3498db;
  color: white;
  border: 1px solid black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 2px;
  cursor: pointer;
  border-radius: 4px;
}

 .control-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
  background-color: black;
  border:1px solid black;
  border-radius: 10px 10px 0 0;
  min-width: 298px;
}

  .control-panel.form {
    justify-content: flex-start;
    width: 370px;
    margin: auto;
    padding: 5px;
  }

  .control-group.form {
    display: block;
    width: 100%;
    justify-content: space-between; 
  }

  .control-group.form select, .control-button.form {
    width: 100%;
  }

.control-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.scheduled-shift {
  background-color: #d1f0ff ; 
}

.activated-shift {
  background-color: white;
}
.name-job-big {
  max-width: 90px;
}

.name-job-small {
  max-width: 55px;
}

.control-group label {
  display: none; 
}

.control-button, .control-group select {
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  width: 120px;
  text-align: center;
}

.control-button {
  border-radius: 10px 10px 0 0;
}

.control-group select {
  flex-grow: 1; 
}

.control-button {
  white-space: nowrap; 
}

.control-button:active,
.control-button:hover,
.control-group select:active,
.control-group select:hover {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  overflow-y: auto;
  z-index: 999;
}

.modal-overlay.all{
  align-items:normal;
}

.editJob-form, .newJob-form, .shift-form{
  width: 300px;
  min-width: 200px;
  margin: auto;
  padding: 0 40px 20px 40px;
  border: 1px solid black;
  border-radius: 0 0 10px 10px;
  background-color: black
}

.newJob-title, .editJob-title, .shift-title {
  text-align: center;
  color: #eaeaea;
}

.newJob-attribute, .editJob-attribute, .shift-attribute {
  margin-bottom: 1vh;
  min-width: 250px;
}

.newJob-attribute label, .editJob-attribute label, .shift-attribute label {
  display: block;
  margin-bottom: 1.5vh;
  color: #eaeaea;
}

.newJob-attribute input, .editJob-attribute input, .editJob-attribute select, .shift-attribute input {
  box-sizing: border-box;
  width: 100%;
  padding-top: 2vh;
  border: 1px solid #ddd;
  border-radius: 1vw;
  align-items:center;
  padding: 10px;
  color: black;
}

.save-cancel-button-job {
  text-align: center;
}

.save-cancel-button-job button {
  width: 48%;
  padding: 2vh;
  background-color: #3498db;
  color: #fff;
  border: 1px solid black;
  border-radius: 1vw;
  cursor: pointer;
  margin: 1vh 0 0 2%;
}

.save-cancel-button-job button:hover {
  background-color: #2980b9;
}

.confirm-button{
  background-color: #4d9aff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}


/*-------MODAL CONFIRMATION-------*/

.modal-confirmation {
  background-color: rgba(0, 0, 0, 0.8);
  margin: 4vh;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #5a5a5a;
  max-width: 400px;
  color: white;
  min-width: 327px;
}

.confirmation-title {
  font-size: 20px;
}

.confirmation-message {
  margin-bottom: 20px;
}

.confirmation-buttons {
  display: flex;
  justify-content: flex-end;
}

.delete-button {
  background-color: #ff4d4d; 
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}

.cancel-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: 1px solid #32373f;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover{
  background-color: rgba(255, 77, 77, 0.9); 
  box-shadow: 0 0 10px rgba(255, 77, 77, 0.5); 
}

.cancel-button:hover{
  background-color: #1a1a1a;
}

/*-----*/

.money-box {
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  margin-bottom: 5vh;
  min-width: 298px;
  color: black;
  text-align: center;
}

.earnings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  padding: 5px;
}

.earnings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  padding: 5px;
}

.delete-job, .delete-shift {
  color: red; 
  cursor: pointer; 
}

.delete-job:hover, .delete-shift:hover {
color: rgb(255, 106, 106); 
}

.filter-icon{
  width:32px;
  height: 32px;
}

@media (max-width: 561px){
  .control-panel {
    justify-content: flex-start;
  }

  .control-group {
    display: block;
    width: 100%;
    justify-content: space-between; 
  }

  .control-group select, .control-button {
    width: 100%;
  }

  .historial-container{
    width: 95%;
  }
}

@media (max-width: 489px){
  .filter-button {
    margin-top: 22px;
  }
}

@media (max-width: 400px){
  .editJob-form, .control-panel.form, .newJob-form, .shift-form{
      width: auto;      
  }

  .editJob-form, .newJob-form {
    padding: 0 30px 20px 40px;
    min-width: 238px;
  }

}

@media (max-width: 340px){
  .historial-container {
    margin: 10vh auto 20px auto;
  }

  .newJob-attribute{
    min-width: auto;
  }
}

/* ************** */

.manage-tools-dropdown {
  background-color: #fff;
  min-width: 230px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.control-group:hover .manage-tools-dropdown {
  display: block;
}

.dropdown-item {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.historial-cancel-button{
  background-color: #2874A6 !important;
}