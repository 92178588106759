.login-container {
    width: 300px;
    min-width: 200px;
    margin: auto 10px;
    padding: 3vh 5vh 3vh 5vh;
    border: 1px solid black;
    border-radius: 2vw;
    background-color: rgba(0, 0, 0, 1);
    color: white;
}

.recovery-container-login {
    width: 400px;
    min-width: 200px;
    margin: auto 10px;
    padding: 5vh;
    border: 1px solid black;
    border-radius: 2vw;
    background-color: rgba(0, 0, 0, 1);
    color: white;
}

.login-title {
    text-align: center;
    color: #eaeaea;
}

.login-attribute {
    margin-bottom: 2vh;
}

.login-attribute label {
    display: block;
    margin-bottom: 1.5vh;
    color: #eaeaea;
}

.login-attribute input {
    width: 100%;
    padding: 1.5vh 0 1.5vh 0;
    border: 1px solid #ddd;
    border-radius: 1vw;
    text-indent: 5px;
}

.submit-button-login button {
    width: 100%;
    padding: 2vh;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 1vw;
    cursor: pointer;
}

.submit-button-login button:hover {
    background-color: #2980b9;
}

.login-createAccount {
    text-align: center;
    margin-top: 3vh;
}

.login-createAccount a {
    color: #3498db;
    text-decoration: none;
}

.login-createAccount a:hover {
    text-decoration: underline;
}

.span-button{
    cursor: pointer;
    text-decoration: none;
    color: #3498db;
    margin-bottom: 3vh;
}

.span-button:hover {
    text-decoration: underline;
}

.login-forgot-cancel {
    text-align: center;
    margin-top: 3vh;
}

.login-forgot-cancel a {
    color: #3498db;
    text-decoration: none;
}

.login-forgot-cancel a:hover {
    text-decoration: underline;
}

  .instructions-recovery{
    padding: 0;
  }

  .instructions-recovery li {
    padding-bottom: 10px;   
  }

  .login-page .grecaptcha-badge {
    display: block !important;
    visibility: visible !important;
  }

@media (max-width: 480px){
    .recovery-container-login{
        width: auto;
    }
}

@media (max-width: 400px) {
    .login-container {
        width: auto;
        min-width: 260px;
    }
}
