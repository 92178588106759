.profile-container {
    width: 300px;
    min-width: 200px;
    margin: 20vh 10px 10px 10px;
    padding: 25px 35px 30px 35px;
    border: 1px solid black;
    border-radius: 2vw;
    background-color: black;
  }

  .profile-container.max{
    margin: 12vh 0 5vh 0;
  }
  
.profile-title {
    text-align: center;
    color: #eaeaea;
}

.profile-attribute {
    margin-bottom: 1vh;
}

.profile-attribute label {
    display: block;
    margin-bottom: 1.5vh;
    color: #eaeaea;
}

.profile-attribute input, .profile-attribute select {
    width: 100%;
    padding: 1.5vh 0 1.5vh 0;
    border: 1px solid #ddd;
    border-radius: 1vw;
    text-indent: 5px;
    pointer-events: none;
}

.profile-attribute-switch {
  margin: 2vh 0 1vh 0;
  color: white;
}

.edit-button button {
    width: 100%;
    padding: 2vh;
    margin-top: 2vh;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 1vw;
    cursor: pointer;
}

.save-cancel-button {
    text-align: center;
    margin-top: 10px;
}

.save-cancel-button button {
    width: 48%;
    padding: 2vh;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 1vw;
    cursor: pointer;
    margin: 1vh 0 0 2%;
}

.edit-button button:hover {
    background-color: #2980b9;
}

.save-cancel-button button:hover {
    background-color: #2980b9;
}

.profile-cancel-button{
    background-color: #2874A6 !important;
  }

.profile-goToLogin {
    text-align: center;
    margin-top: 3vh;
    color: #aeaeae;
}

.profile-goToLogin a {
    color: #3498db;
    text-decoration: none;
}

.profile-goToLogin a:hover {
    text-decoration: underline;
}

/**********************/


.delete-account {
    color: red; 
    cursor: pointer; 
  }
  
.delete-account:hover {
color: rgb(255, 106, 106); 
}

.modal-overlay-delete {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px); 
    color: white;
    z-index: 999;
  }

.modal-confirmation-delete {
    background-color: rgba(0, 0, 0, 1);
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #5a5a5a;
    min-width: 20vw;
    width: 330px;
}

.confirmation-title-delete {
    font-size: 20px;
    text-align: center;
}

.confirmation-message-delete {
    margin-bottom: 20px;
    text-align: center;
}

.delete-profile {
    background-color: #21262e; 
  }
  
.cancel-profile {
    background-color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.confirmation-inputs-delete input {
    width: 100%;
    padding: 1.5vh 0 1.5vh 0;
    margin-bottom: 10px;
    border: 1px solid red;
    border-radius: 1vw;
    background-color: black;
    color: white;
    text-indent: 5px;
}

.confirmation-inputs-delete label {
    
    display: block;
    margin-bottom: 1.5vh;
    color: #eaeaea;

}

.confirmation-buttons-delete button {
    
    width: 48%;
    padding: 2vh;
    color: #fff;
    border: 1px solid #32373f;
    border-radius: 1vw;
    cursor: pointer;
    margin: 1vh 0 0 2%;
  margin-left: 5px; 
}

.delete-profile:hover{
background-color: rgba(255, 77, 77, 0.9); 
box-shadow: 0 0 10px rgba(255, 77, 77, 0.5); 
}

.cancel-profile:hover {
    background-color: #1a1a1a;
}

.tooltip-content {
    text-align: left;
  }

.list-item {
    color: #000; 
}

.list-item.valid {
    color: green; 
}

.profile-page .grecaptcha-badge {
    display: block !important;
    visibility: visible !important;
  }

  /*Slider cookies selector*/
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #2196f3;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

@media (max-width: 400px){
    .profile-container{
        width: 75%;
        min-width: 230px;
    }
}
  

