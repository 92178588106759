.purpose-container {
    display: flex;
    margin-top: 11vh;
    flex-direction: column;
    align-items: center;
    user-select: none;
  }

  .image-phone, .image-icon1, .image-tablet {
    display: none;
  }

  .purpose-container img {
    display: block;
  }
  
  .title-purpose {
    text-align: center;
    margin-bottom: 40px;
    font-size: 50px;
    white-space: nowrap;
  }

  .images-container {
    display: flex;
    gap: 30px;
    margin-bottom: 60px;
  }

  .images-container.home-section{
    margin-bottom: 30px;
  }

  .images-container p{
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .image-block {
    text-align: center;
  }
  
  .image-phone {
    width: 250px; 
  }
  .image-phone:hover {
    transform: scale(1.05); 
    transition: transform 0.5s ease, fill 0.5s ease;
  }

  .image-tablet {
    width: 800px; 
    margin-top: 40px;
  }


  .info-section {
    display: flex;
    justify-content: center; 
    gap: 20px; 
    margin-top: 30px; 
    
  }
  
  .info-text {
    text-align: left;
    flex: 1;
    width: 500px;
    font-size: 1.2rem;
  }

  .info-text-centered{
    text-align: center; 
    flex: 1; 
    min-width: 300px; 
    max-width: 450px;
    font-size: 1.2rem;
  }
  
  .info-image {
    flex: 1; 
    text-align: center;
  }
  
  .image-icon1 {
    width: 250px; 
    height: 250px; 
  }


  .info-image svg {
    transition: transform 0.3s ease, fill 0.3s ease; 
  }
  
  .info-image svg:hover {
    transform: scale(1.1); 
    fill: #ff6347; 
  }

  .image-tablet {
    width: 800px; 
    transition: box-shadow 0.3s ease, border 0.3s ease;
    border: 1px solid transparent;
    border-radius: 50px;
  }
  
  /* Hover state */
  .image-tablet:hover {
    box-shadow: 0 0 10px #3498db; 
    border: 1px solid #3498db; 
  }


  /*---------------------------------------------*/

  .text-your-time1,
  .text-your-time2,
  .text-your-time3 {
    transition: color 0.3s ease, text-shadow 0.3s ease; 
  }
  
  .text-your-time1:hover {
    color: #bfbe6e; 
    text-shadow: 0 0 2px #bfbe6e; 
  }
  
  .text-your-time2:hover {
    color: #bfbe6e; 
    text-shadow: 0 0 2px #bfbe6e; 
  }
  
  .text-your-time3:hover {
    color: #bfbe6e; 
    text-shadow: 0 0 2px #bfbe6e; 
  }

  .fade-in {
    animation: fadeIn 1s ease-out; 
}

  /*---------------------------------------------*/

  @media (max-width: 1100px){
   

    .images-container.home-section {
      margin-bottom: 0;
    }
  }


  @media (max-width: 850px) {
    .title-purpose {
        font-size: 40px;
    }

    .image-tablet{
      width: 500px;
      border-radius: 0px;
      border: none;
      transition: none;
    }

    .image-tablet:hover {
      box-shadow: none;
      border: 0px;
    }

    .images-container {
      margin-bottom: 50px;
    }
  }

  @media (max-width: 660px){
    .title-purpose {
      white-space: collapse;
      max-width: 100vw;
    }

    .image-tablet {
      width: 350px;
      margin-bottom: 20px;
    }

    .info-text-centered, .info-text{
      max-width: 350px;
      text-align: center;
    }

    .info-section{
      padding-top: 10px;
    }

    .images-container{
      margin-bottom: 10px;
    }
  }




  @keyframes fadeIn {
    from {
        opacity: 0; 
        transform: translateY(30px); 
    }
    to {
        opacity: 1; 
        transform: translateY(0); 
    }
}
