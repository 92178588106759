.main-content  {
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(145deg, #dae2f8, #d4d6a4); 
  position: relative;
  background-size: cover; 
  background-position: center; 
}

.privacy-content { /* No align-items: center */
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  position: relative;
  background-size: cover; 
  background-position: center; 
}


.message-alert {
    margin-bottom: 20px;
    text-align: center;
    
    border-radius: 6px;
    padding: 4px;
}

.error-message-container{
    height: 30px;
  }

.message-alert.orange{
    background-color: #978571;
    border: 1px solid #ffe300;
    color: white;
}

.message-alert.green{
    background-color: #729771;
    border: 1px solid #00ff51;
    color: white;
}

.message-alert.red{
    background-color: #9a5656;
    border: 1px solid #f00;
    color: white;
}

.message-alert.grey{
    background-color: #6b6b6b;
    border: 1px solid #fff;
    color: white;
}

.modal-no-session {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px); /* Blur effect */
    overflow-y: auto;
  }

  .modal-confirmation-no-session {
    background-color: rgba(0, 0, 0, 0.8);
    margin: 4vh;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #5a5a5a;
    max-width: 400px;
    color: white;
    min-width: 327px;
  }
  
  
  .confirmation-title-no-session {
    font-size: 20px;
  }
  
  .confirmation-message-no-session {
    margin-bottom: 20px;
  }
  
  .confirmation-buttons-no-session {
    display: flex;
    justify-content: flex-end;
  }
  
  .button-no-session {
    background-color: #4d9aff; 
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .button-no-session:hover{
    background-color: rgba(131, 205, 254, 0.9); /* Color de fondo más brillante */
    box-shadow: 0 0 10px rgba(255, 77, 77, 0.5); /* Sombra más pronunciada */
  }

  .modal-loading{
    position: fixed;
    width: 100vw;
    height: 79vh;
    padding: 30px 0 30px 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .modal-loading-title {
    margin-top: 20vh;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  /**********************/

  .modal-success, .modal-error {
    background-color: #ffffff;
    max-width: 400px; 
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    border: 1px solid #747474; 
    text-align: center; 
}

  .modal-error button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: 1px solid #32373f;
    border-radius: 4px;
    cursor: pointer; 
  }
  
  .modal-header {
    margin-bottom: 20px;
    min-width: 327px;
  }
  
  .modal-header img {
    width: 64px; 
    height: auto;
    margin-bottom: 10px; 
  }
  
  .modal-header h3 {
    margin: 0;
    font-size: 24px; 
  }
  
  .modal-body p {
    margin: 10px 0; 
    color: #666; 
  }
  
  .modal-body p small {
    display: block; 
    margin-top: 10px; 
    font-size: 14px; 
    color: #999; 
  }


  /*--------------DATE PICKER------------*/

  .react-datepicker__input-container input {
    height: 5vh; 
    width: 200px;
    border-radius: 1vw;
    border: 1px solid white;
    background-color: #161b21; 
    color: white; 
    text-align: center;
  }
  
  .react-datepicker__month{
    background-color: #161b21;
  }
  
  .react-datepicker__day{
    color:white !important;
  }
  
  .react-datepicker__month-container{
    background-color: black;
  }
  
  .react-datepicker__day--disabled{
    color: gray !important;
  }
  
  .react-datepicker__day--keyboard-selected{
  background-color: #216ba5 !important; 
  }
  
  .react-datepicker__day--in-range:hover{
   background-color: #216ba5 !important;
  }
  
  .react-datepicker__day:hover{
    background-color: rgba(33, 107, 165, 0.5) !important;
  }
  
  /* ----- */
   .react-datepicker__day--in-range:hover {
    background-color: rgba(33, 107, 165, 0.5) !important;
  } 
  
  .react-datepicker__input-container input::placeholder {
    color: white; 
    text-align: center; 
  }

  html .react-datepicker__input-container input::placeholder {
    opacity: 1 !important;
  }

  .bar-graph-vertical .bar {
    width: 20px;
  }

  .grecaptcha-badge {
    z-index: 1002;
  }


  /* IMAGES */

/* Add a placeholder while the images are loading */
.lazy-image {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

/* It shows the imagen when it's loaded */
.lazy-image.loaded {
  opacity: 1;
}
  