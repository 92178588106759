.privacy-container {
  /* margin: 0 auto; */
  padding: 10vh 0 5vh 0;
  text-align: center;
  background-color: white;
}

.privacy-divider {
  border: 0;
  border-top: 0.2vw solid #ccc;
  margin-left: 10vw;
  margin-right: 10vw;
}

.privacy-content {
  margin-left: 10vw;
  margin-right: 10vw;
  text-align: left;
}

.privacy-content ul li {
  margin-bottom: 10px;
}

.privacy-content p {
  margin-bottom: 8px;
}

.privacy-content h2 {
  margin-bottom: 5px;
}


.url-privacy {
  color: #3498db;
  text-decoration: none;
}

.url-privacy:hover {
  text-decoration: underline;
}

@media screen and (max-width: 560px) {

  .cookies-table {
    font-size: 0.9em;
  }

  .cookies-table td, .cookies-table th{
    padding: 3px;
  }

  .cookies-table-container{
    overflow: scroll;
  }
}

/* ------------------- */
.cookies-table-container{
  border: 1px solid #606060;
  border-radius: 8px; 
  overflow: scroll;
}
.cookies-table {
  width: 100%;
  border-collapse: collapse;
}

.cookies-table th,
.cookies-table td {
  border: 1px solid #d3d3d3;
  padding: 8px;
}

.cookies-table th:first-child,
.cookies-table td:first-child {
  border-left: none;
}

.cookies-table th:last-child,
.cookies-table td:last-child {
  border-right: none;
}

.cookies-table tr:last-child td {
  border-bottom: none;
}

.cookies-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.cookies-table tr:hover {
  background-color: #f1f1f1;
}

.cookies-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.cookies-table th {
  background-color: black;
  color: white;
  border-top: none;
}